body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", "Rubik"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: 
  grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}
